<template>
  <div class="activity-question box box--sm box--no-shadow">
    <p class="subtitle activity-question__header">{{question.stem}}</p>
    <div class="activity-question__answer">

      <!-- selección única -->
      <div v-if="question.kind === 'MULTIPLE-CHOICE-SINGLE-OPTION'" class="radio-answer">
        <div v-for="(alternative, index) in question.alternatives" :key="index" class="radion-answer__option">
          <input class="radio-answer__input" type="radio" :value="alternative" v-model="answer" :id="`question-${question.id}-alternative-${index}`">
          <label class="radio-answer__label" :for="`question-${question.id}-alternative-${index}`">
            <p>{{alternative}}</p>
          </label>
        </div>
      </div>

      <!-- selección múltiple -->
      <div v-else-if="question.kind === 'MULTIPLE-CHOICE-MULTIPLE-OPTION'" class="radio-answer">
        <div v-for="(alternative, index) in question.alternatives" :key="index" class="radion-answer__option">
          <input class="radio-answer__input" :value="alternative" v-model="answer" type="checkbox" :id="`question-${question.id}-alternative-${index}`">
          <label class="radio-answer__label" :for="`question-${question.id}-alternative-${index}`">
            <p>{{alternative}}</p>
          </label>
        </div>
      </div>

      <!-- Abierta -->
      <div v-else-if="question.kind === 'OPEN-ENDED'" class="open-answer">
        <div class="form__group">
          <textarea class="form__input" v-model.trim="answer" placeholder="Introduce tu respuesta"></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DisplayQuestion',
  props: ['modelValue', 'question'],
  emits: ['update:modelValue'],
  data () {
    return {
      myAnswer: null
    }
  },
  computed: {
    answer: {
      get () { return this.modelValue },
      set (val) { this.$emit('update:modelValue', val) }
    }
  }
}
</script>
